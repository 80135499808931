import React, { useEffect, useState,lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Create_a_job from "../pages/create_new_job";
import Forgot_password from "../pages/forgot_password"; 
// const Forgot_password = lazy(() => delayForDemo(import('../pages/forgot_password')));
import Setforgotpassword from "../pages/setforgotpassword";
import Signin from "../pages/signin";
// const Signin =lazy(()=>import('../pages/signin'))
// const Signup =lazy(()=>import('../pages/signup'))
// const Signin =lazy(()=>import('../pages/signin'))
import Signup from "../pages/signup";
// const Signup =lazy(()=>import('../pages/signup'))
// import About from "../pages/About.js";
const About = lazy(() => import("../pages/About"));

const ClientCreateJob = lazy(() => import("../pages/ClientCreateJob"));
// import ClientCreateJob from "../pages/ClientCreateJob.js";
const TalentPoolNew = lazy(() => import("../pages/talent_pool_new"));
// import TalentPoolNew from "../pages/talent_pool_new.jsx";
const JobDetails = lazy(() => import("../pages/JobDetails"));
// import JobDetails from "../pages/JobDetails.js";
const JobPage = lazy(() => import("../pages/job-new"));
// import JobPage from "../pages/job-new";
const HomeLayout = lazy(() => import("../Layout/HomeLayout.jsx"));
// import HomeLayout from "../Layout/HomeLayout";
const TeamMembers = lazy(() => import("../pages/add_and_edit_users.js"));
// import TeamMembers from "../pages/add_and_edit_users";
const AddPod = lazy(() => import("../pages/pod_add_page"));
// import AddPod from "../pages/pod_add_page";
const Change_password = lazy(() => import('../pages/Change_password'));
import PasswordAndSecurity from "../pages/PasswordAndSecurity";

const Applications = lazy(() => import('../pages/applications_new'));
// import Applications from "../pages/applications_new";
const Dashboard = lazy(() => import('../pages/Dashborad'));
// import Dashboard from "../pages/dashboard";
const MainDashboardComponent = lazy(() => import('../pages/main-dashboard.js'));
// import MainDashboardComponent from "../pages/main-dashboard";
const DailyReportComponent = lazy(() => import('../pages/main_daily_report'));
// import DailyReportComponent from "../pages/main_daily_report";
const JobAnalysis = lazy(() => import('../pages/job-analysis.js'));
// import JobAnalysis from "../pages/job-analysis";
const CandidateReport = lazy(() => import('../pages/candidate_report'));
// import CandidateReport from "../pages/candidate_report";
const JobAnalyticsNew = lazy(() => import('../pages/JobAnalytics/JobAnalyticsNew.jsx'));
// import Scorecard from "../pages/scorcard";
const Scorecard = lazy(()=>import ('../pages/scorcard'))
const AddMember =lazy(()=>import('../pages/add_member'))
// import AddMember from "../pages/add_member";
import packageJson from "../../package.json";
const NewTalentCard = lazy(() => import('../components/Talent-Card'));
// import NewTalentCard from "../components/Talent-Card";
const NewTalentCardUI = lazy(() => import('../components/New-TalentCard'));
// import NewTalentCardUI from "../components/New-TalentCard";
const ClientTab = lazy(() => import('../components/clientTab/ClientsJob'));
// import ClientTab from "../components/clientTab/ClientsJob";
const CreateJob = lazy(() => import('../pages/CreateJob'));
import { useDispatch, useSelector } from "react-redux";
import InterviewList from "../pages/InterviewList.jsx";
// import { useSelector } from "react-redux";
import Swal from "sweetalert2";
// import NewTalentCard from '../components/Talent-Card'
// import TalentCard from "../components/Talent-Card";
const AddingToken = lazy(() => import('../components/candidate/Forms/AddingToken'));
// import AddingToken from "../components/candidate/Forms/AddingToken";
const OAuthFailed = lazy(() => import('../components/candidate/Forms/OAuthFailed'));
// import OAuthFailed from "../components/candidate/Forms/OAuthFailed";
const Choose = lazy(() => import('../pages/Choose.js'));
// import Choose from "../pages/Choose.js";
const EditProfile = lazy(() => import('../pages/EditProfile.js'));
// import Settings from "../pages/Settings.js"
const Settings =lazy(()=>import ('../pages/Settings.js'))
// import Branding from "../pages/Branding.js"
const Branding =lazy(()=>import('../pages/Branding.js'))
const AdminDashboard = lazy(() => import('../pages/AdminDashboard/AdminDashboard.js'));
// import AdminDashboard from "../pages/AdminDashboard/AdminDashboard.js";
const HiringReport = lazy(() => import('../pages/HiringReport/HiringReport.js'));
import ChatIndex from "../components/chat/ChatIndex.js";
import { getAllClient } from "../actions/action_client.js";
const InterviewListTab = lazy(() => import('../pages/InterviewListTab'));
// import InterviewListTab from "../pages/InterviewListTab.jsx";
const ClientParterTab = lazy(() => import('../pages/Client-Partner-Tab.jsx'));
// import ClientParterTab from "../pages/Client-Partner-Tab.jsx";
const PartnerTalentpool = lazy(() => import('../pages/PartnerTalentpool'));
// import PartnerTalentpool from "../pages/PartnerTalentpool.jsx";
const MappingTab = lazy(() => import('../pages/Market-Intelligence/MappingTab'));
// import MappingTab from "../pages/Market-Intelligence/MappingTab.jsx";
const MappedCandidates = lazy(() => import('../pages/Market-Intelligence/MappedCandidates'));
// import MappedCandidates from "../pages/Market-Intelligence/MappedCandidates.jsx";
const ShareMappedTalent = lazy(() => import('../pages/Market-Intelligence/ShareMappedTalent'));
// import PreviewCompany from "../pages/PreviewCompany.js";
const PreviewCompany= lazy (()=>import ('../pages/PreviewCompany.js'))
import { ChangePass } from "../actions/action_auth.js";
// import Change_Password_Admin from "../pages/Change_Password_Admin.js";
const Change_Password_Admin = lazy(()=>import('../pages/Change_Password_Admin.js'))
export default function Routes() {
  const checkAuth = () => {
    const token = window.localStorage.getItem("accessToken");
    const isAuthenticated = useSelector((state) => state.user.user.auth);
    if (!isAuthenticated) {
      return false;
    }
    return true;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllClient());
  }, []);

  const userRole = useSelector((state) => state.user.user);
  // const checkAuth = async () => {
  //   const token = localStorage.getItem("accessToken");
  //   const url = `http"//localhost:5002/auth/google-login/success`;
  //   const { data } = await axios.get(url, { withCredentials: true });
  //   if (!token || !data.token) {
  //     return false;
  //   }
  //   return true;
  // };

  // const [user, setUser] = useState(null);
  // const getUser = async () => {
  // 	try {
  // 		const url = `http"//localhost:3000/auth/google-login/success`;
  // 		const { data } = await axios.get(url, { withCredentials: true });
  // 		setUser(data.user._json);
  // 	} catch (err) {
  // 	}
  // };

  // useEffect(() => {
  // 	getUser();
  // }, []);

  // useEffect(() => {
  //   checkAuth();
  //   caching();
  // });

  let routes = useRoutes([
    // {
    //   path: "/",
    //   element: checkAuth() ? (
    //     <HomeLayout>
    //       <JobPage />
    //     </HomeLayout>
    //   ) : (
    //     <Navigate to="/signin" />
    //   ),
    // },
    {
      path: "/",
      element: checkAuth() ? (
        <HomeLayout>
          {userRole.role === 'client' ? <Dashboard /> : <JobPage />}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/dashboard",
      element: checkAuth() ? (
        <HomeLayout>
          <Dashboard />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/member/:token",
      element: <AddMember />,
    },
    {
      path: "/job-report",
      element: checkAuth() ? (
        <HomeLayout>
          <MainDashboardComponent />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/daily-report",
      element: checkAuth() ? (
        <HomeLayout>
          <DailyReportComponent />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job-analysis",
      element: checkAuth() ? (
        <HomeLayout>
          <JobAnalysis />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job-analytics",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <JobAnalytics /> */}
          <JobAnalyticsNew />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/admin-dashboard",
      element: checkAuth() ? (
        <HomeLayout>
          <AdminDashboard />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/hiring-report",
      element: checkAuth() ? (
        <HomeLayout>
          <HiringReport />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job-details/:jobId",
      element: checkAuth() ? (
        <HomeLayout>
          <JobDetails />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },

    // {
    //   path: "/job-analytics/:clientId",
    //   element: checkAuth() ? (
    //     <HomeLayout>
    //       <JobAnalytics />
    //     </HomeLayout>
    //   ) : (
    //     <Navigate to="/signin" />
    //   ),
    // },

    {
      path: "/job-analytics/:clientId",
      element: checkAuth() ? (
        <HomeLayout>
          <JobAnalyticsNew />
          {/* <JobAnalytics /> */}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    // {
    //   path: "/InterviewList/:clientId",
    //   element: checkAuth() ? (
    //     <HomeLayout>
    //       {/* <JobAnalytics /> */}
    //       <InterviewListTab />
    //     </HomeLayout>
    //   ) : (
    //     <Navigate to="/signin" />
    //   ),
    // },

    {
      path: "/candidate-report",
      element: checkAuth() ? (
        <HomeLayout>
          <CandidateReport />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/signin",
      element: <Signin />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/choose",
      element: <Choose />,
    },
    {
      path: "/forgot-password",
      element: <Forgot_password />,
    },
    {
      path: "/setforgotpassword/:token",
      element: <Setforgotpassword />,
    },
    {
      path: "/auth/social/:token/login",
      element: <AddingToken />,
    },
    {
      path: "/auth/social/failed",
      element: <OAuthFailed />,
    },
    {
      path: "/client/about",
      element: checkAuth() ? (
        // <HomeLayout>
        <About />
      ) : (
        // {" "}
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/passwordandsecurity",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <ChangePass /> */}
          <Change_password />
          {/* <PasswordAndSecurity /> */}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/preview/:clientName/:clientId",
      element: checkAuth() ? (
        <HomeLayout>
          <PreviewCompany />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/branding",
      element: checkAuth() ? (
        // <HomeLayout>
        <Branding />
      ) : (
        // {" "}
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/editprofile",
      element: checkAuth() ? (
        // <HomeLayout>
        <EditProfile />
      ) : (
        // {" "}
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/settings",
      element: checkAuth() ? (
        // <HomeLayout>
        <Settings />
      ) : (
        // {" "}
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/client/job/create",
      element: checkAuth() ? (
        // <HomeLayout>
        <ClientCreateJob />
      ) : (
        // {" "}
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job/:basic",
      element: checkAuth() ? (
        <HomeLayout>
          <Create_a_job />{" "}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/createJob",
      element: checkAuth() ? (
        <HomeLayout>
          <CreateJob />{" "}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/edit/:id",
      element: checkAuth() ? (
        <HomeLayout>
          <CreateJob />{" "}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/InterviewList",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <InterviewList /> */}
          <InterviewListTab />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/mapping",
      element: checkAuth() ? (
        <HomeLayout>
          <MappingTab />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/marketIntel/:id",
      element: checkAuth() ? (
        <HomeLayout>
          <MappedCandidates />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/sharedMapping/:id",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <MappedCandidates /> */}
          <ShareMappedTalent />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/mapping/:id",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <MappedCandidates /> */}
          <ShareMappedTalent />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job/:basic/:id",
      element: checkAuth() ? (
        <HomeLayout>
          <Create_a_job />{" "}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job/edit/:id",
      element: checkAuth() ? (
        <HomeLayout>
          <Create_a_job />{" "}
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/talent-pool",
      element: checkAuth() ? (
        <HomeLayout>
          <TalentPoolNew />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/partner-talentpool",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <TalentPoolNew /> */}
          <PartnerTalentpool />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/talent_pool/candidate/:cand_id/:stage",
      element: checkAuth() ? (
        // <HomeLayout>
        // <Candidate />
        // <TalentCard />
        <NewTalentCardUI />
      ) : (
        // <NewTalentCardUI />
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/talent_pool/candidate/:cand_id/:stage/sales",
      element: (
        // <HomeLayout>
        // <Candidate />
        // <TalentCard />
        <NewTalentCardUI />
      ),
    },
    {
      path: "/sharedMapping/:id/customerSales",
      element: (
        <HomeLayout>
          <ShareMappedTalent />
        </HomeLayout>
        // <Candidate />
        // <TalentCard />
        // <NewTalentCardUI />
      ),
    },
    {
      path: "/sharedMapping/:id/sample",
      element: (
        <HomeLayout>
          <ShareMappedTalent />
        </HomeLayout>
        // <Candidate />
        // <TalentCard />
        // <NewTalentCardUI />
      ),
    },
    {
      path: "/talent_pool/candidates/:cand_id/:stage",
      element: checkAuth() ? (
        // <HomeLayout>
        // <TalentCard />
        <NewTalentCardUI />
      ) : (
        // </HomeLayout>
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/talent_pool/candidate/:cand_id/:stage/public",
      element: (
        // <HomeLayout>
        // <Candidate />
        // <NewTalentCard />
        <NewTalentCardUI />
        // <TalentCard />
        // </HomeLayout>
      ),
    },
    {
      path: "/job/:job_id/candidate/:cand_id/:stage/public",
      element: (
        // <HomeLayout>
        // <Candidate />
        // <NewTalentCard />
        <NewTalentCardUI />
        // </HomeLayout>
      ),
    },
    {
      path: "/job/:job_id/candidate/:cand_id/:stage/sales",
      element: (
        // <HomeLayout>
        // <Candidate />
        // <JobTalentCard />
        <NewTalentCardUI />
        // </HomeLayout>
      ),
    },
    {
      path: "/team-members",
      element: checkAuth() ? (
        <HomeLayout>
          <TeamMembers />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/add-client",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <AddClient /> */}
          <ClientParterTab />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/add-pod",
      element: checkAuth() ? (
        <HomeLayout>
          <AddPod />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/curation",
      element: checkAuth() ? (
        <HomeLayout>
          <Scorecard />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/new-talentcard",
      element: checkAuth() ? (
        <HomeLayout>
          <NewTalentCard />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/changepassword",
      element: checkAuth() ? (
        <HomeLayout>
          {/* <Change_password /> */}
          <Change_Password_Admin />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
   
    {
      path: "/jobs",
      element: checkAuth() ? (
        <HomeLayout>
          <JobPage />
         </HomeLayout> 
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/job/:job_id/candidate/:cand_id/:stage",
      element: checkAuth() ? (
        //  <JobTalentCard />
        <NewTalentCardUI />
      ) : (
        <Navigate to="/signin" />
      ),
    },
    // {
    //   path: "/job/:job_id/candidate/:cand_id/:stage",
    //   element: checkAuth() ? <NewTalentCardUI /> : <Navigate to="/signin" />,
    // },
    {
      path: "/job/applications/:stage/:id/:job",
      element: checkAuth() ? (
        <HomeLayout>
          <Applications />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/clientTab",
      element: checkAuth() ? (
        <HomeLayout>
          <ClientTab />
        </HomeLayout>
      ) : (
        <Navigate to="/signin" />
      ),
    },
    {
      path: "/talent-pool/:sharedpoolId/public",
      element: (
        <HomeLayout>
          <TalentPoolNew isPublic={true} />
        </HomeLayout>
      ),
    },
    // {
    //   path: "/inbox",
    //   element: (
    //     <HomeLayout>
    //       <ChatIndex />
    //     </HomeLayout>
    //   ),
    // },
    {
      path: "/new-talent-card/talent_pool/candidate/:cand_id/:stage",
      element: <NewTalentCardUI />,
    },
  ]);
  return routes;
}

function caching() {
  let version = window.localStorage.getItem("version");
  // if(2!=2){}
  if (version != packageJson.version) {
    // return 0;
    if ("caches" in window) {
      // return 0;
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
    window.localStorage.clear();
    window.localStorage.setItem("version", packageJson.version);
  }
}
